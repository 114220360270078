import Rails from "@rails/ujs";

Rails.start();
require.context("../images", true);

import "bootstrap-sass/assets/javascripts/bootstrap";

import "select2/dist/js/select2.full";
import "select2/dist/js/i18n/de";

import "bootstrap-jasny/js/fileinput";

// Set the date we're counting down to
let countDownDate = new Date("June 30, 2024 23:59:59").getTime();

const countdown = function () {
  // Get today's date and time
  let now = new Date().getTime();

  // Find the distance between now and the count down date
  let distance = countDownDate - now;

  if (distance < 0) {
    return;
  }

  // Time calculations for days, hours, minutes and seconds
  let days = ("0" + Math.floor(distance / (1000 * 60 * 60 * 24))).slice(-2);
  let hours = (
    "0" + Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  ).slice(-2);
  let minutes = (
    "0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  ).slice(-2);
  let seconds = ("0" + Math.floor((distance % (1000 * 60)) / 1000)).slice(-2);

  document.getElementById("days").innerHTML = days;
  document.getElementById("hours").innerHTML = hours;
  document.getElementById("minutes").innerHTML = minutes;
  document.getElementById("seconds").innerHTML = seconds;
};

// Calculate time on load
window.onload = () => {
  if (document.getElementById("days") != undefined) {
    countdown();
    // Update the count down every 1 seconds
    setInterval(countdown, 1000);
  }
};

function mntoggle(selector, that) {
  $(selector).slideToggle(450);
  $(that).hide();
}

window.mntoggle = mntoggle;
